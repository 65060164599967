import React from 'react';
import './Dot.scss';
import filamentIcon from '../../images/icons/filament-icon.svg';

const Dot = (props) => {
  const dashed = props.dashed ? 'dashed' : '';
  const position = props.position ? props.position : '';
  const side = props.side ? props.side : '';
  const align = props.align ? `align-${props.align}` : '';
  return (
    <div
      className={`dot bg-${props.color} ${position} ${side} ${align} ${dashed}`}
    >
      <img src={filamentIcon} alt="Filament Icon" />
    </div>
  );
};

export default Dot;
