import React from 'react';
import Layout from '../components/Layout';
import { Container, Row, Col } from 'react-bootstrap';
import Hero from '../components/Hero/Hero';
import TopBanner from '../components/Banners/TopBanner';
import BottomBanner from '../components/Banners/BottomBanner';
import Feature from '../components/Feature/Feature';
import DashedBox from '../components/DashedBox/DashedBox';
import Faq from '../components/Faq/Faq';
import FaqQuestion from '../components/Faq/FaqQuestion';
import FaqAnswer from '../components/Faq/FaqAnswer';
import SignupForm from '../components/SignupForm/SignupForm';

const Home = () => {
  const seoProps = {
    title: 'The data science notebook for teams',
    description:
      'Filament is a data science notebook that helps the whole team explore, visualise, and use data.',
  };
  return (
    <Layout seoProps={seoProps}>
      <Hero />
      <TopBanner />
      <DashedBox
        id="featuresNav"
        class="dashedBox"
        vBorder="right"
        hBorder="bottom"
        topDot="yellow"
        bottomDot="orange"
        span={5}
        offset={1}
      />
      <Container>
        <Feature
          screenShotSide="right"
          backdrop="orange"
          rotate="left"
          screenshot="feature01"
        >
          <h2>A collaborative notebook for the whole team</h2>
          <p>
            Designed for <b>data-driven teams</b>, Filament combines a powerful
            interactive coding environment with an intuitive WYSIWYG text
            editor.
          </p>
          <p>
            With <b>real-time collaborative editing</b> of text and code,
            whether you’re a data scientist, a marketing executive or a
            researcher, everyone can work on the same page, at the same time.
          </p>
        </Feature>
      </Container>
      <DashedBox
        class="dashedBox mt-4 mb-0"
        vBorder="right"
        hBorder="top"
        topDot="teal"
        bottomDot="sky"
        span={7}
        offset={1}
      />
      <Container>
        <Feature
          screenShotSide="left"
          backdrop="sky"
          rotate="right"
          screenshot="feature02"
        >
          <h2>Ready for remote teams</h2>
          <p>
            Start analysing your data in seconds with Filament’s{' '}
            <b>zero-setup</b> environment.
          </p>
          <p>
            The <b>cloud-native platform</b> is built for remote working; access
            your data and analysis from anywhere, on any device.
          </p>
          <p>
            Avoid downloading data to insecure devices by analysing your
            sensitive data in our <b>secure cloud environment</b>.
          </p>
        </Feature>
      </Container>
      <DashedBox
        class="dashedBox mt-5 mb-0"
        vBorder="left"
        hBorder="top"
        topDot="blue"
        bottomDot="purple"
        span={7}
        offset={1}
      />
      <Container>
        <Feature
          screenShotSide="right"
          backdrop="purple"
          rotate="right"
          screenshot="feature03"
        >
          <h2>Built on open source</h2>
          <p>
            Filament provides <b>Python-based data analysis</b>, and is ready to
            go with all your favourite libraries, including: Pandas, Numpy,
            SciPy, Matplotlib, Scikit-Learn + more.
          </p>
          <p>
            <b>Jupyter-compatible.</b>
          </p>
          <p>
            No vendor lock in, <b>import your .ipynb notebook files</b> and
            export your analysis as Markdown documents or Jupyter notebooks.
          </p>
        </Feature>
      </Container>
      <DashedBox
        class="dashedBox mt-5 mb-0"
        vBorder="right"
        hBorder="top"
        topDot="lilac"
        bottomDot="pink"
        span={7}
        offset={1}
      />
      <Container>
        <Feature
          screenShotSide="left"
          backdrop="pink"
          rotate="left"
          screenshot="feature04"
        >
          <h2>Create instant impact</h2>
          <p>
            Use Filament to share your work in a way everyone can understand.
          </p>
          <p>
            Filament gives your work instant impact by enabling you to publish
            your <b>analysis, plus commentary</b>, in the same document. The
            unique integration of code, data, and context, empowers
            non-technical colleagues to make data-driven decisions.
          </p>
          <p>
            <b>Fine-grained access control</b> means you can share your data
            analysis with an individual colleague, your whole organisation, or
            the entire Internet.
          </p>
        </Feature>
      </Container>
      <BottomBanner />
      <Container>
        <Row className="mt-5">
          <Col>
            <h2 className="text-center mb-4">Frequently Asked Questions</h2>

            <Faq align="left" dotColor="yellow">
              <FaqQuestion>Can I try Filament out for free?</FaqQuestion>
              <FaqAnswer>
                <p>
                  You are currently able to{' '}
                  <SignupForm linkType="link" btnId="link-1">
                    register your interest
                  </SignupForm>{' '}
                  in our private beta. When we move to public beta details of
                  our pricing will be online and a free trial available.
                </p>
              </FaqAnswer>
            </Faq>

            <Faq align="right" dotColor="teal">
              <FaqQuestion>
                What programming languages does Filament support?
              </FaqQuestion>
              <FaqAnswer>
                Filament supports Python, the best/most commonly used language
                for complex scientific and numeric applications.
              </FaqAnswer>
            </Faq>

            <Faq align="left" dotColor="green">
              <FaqQuestion>
                What Python packages are available to me?
              </FaqQuestion>
              <FaqAnswer>
                <p>
                  Our standard environment comes pre-installed with Pandas,
                  Numpy, SciPy, Matplotlib, Scikit-Learn and lots more. If the
                  package you need isn’t available, you can simply `pip install`
                  from inside a Filament page.
                </p>
              </FaqAnswer>
            </Faq>

            <Faq align="right" dotColor="purple">
              <FaqQuestion>
                Can I import my existing iPython notebooks (.ipynb)?
              </FaqQuestion>
              <FaqAnswer>
                Yes, you can import your iPython / Jupyter notebooks into
                Filament.
              </FaqAnswer>
            </Faq>

            <Faq align="left" dotColor="blue">
              <FaqQuestion>
                Can I export from Filament to iPython / Jupyter notebook
                (.ipynb) format?
              </FaqQuestion>
              <FaqAnswer>
                Yes, you can export from Filament to iPython / Jupyter notebook
                (.ipynb) and Markdown formats.
              </FaqAnswer>
            </Faq>

            <Faq align="right" dotColor="sky">
              <FaqQuestion>What version of Python can I use?</FaqQuestion>
              <FaqAnswer>
                Our standard environment comes with Python 3.8.
              </FaqAnswer>
            </Faq>

            <Faq align="left" dotColor="lilac">
              <FaqQuestion>
                Can users select different types of compute resources?
              </FaqQuestion>
              <FaqAnswer>
                Right now we offer a fixed compute resource per page but in the
                very near future we will have the capability to select different
                types of compute resources, like memory-optimised and
                GPU-enabled compute.
              </FaqAnswer>
            </Faq>

            <Faq align="right" dotColor="orange">
              <FaqQuestion>Do you offer support?</FaqQuestion>
              <FaqAnswer>Yes - support is available in-app.</FaqAnswer>
            </Faq>

            <Faq align="left" dotColor="red">
              <FaqQuestion>Do you offer training?</FaqQuestion>
              <FaqAnswer>
                <p>
                  Yes, we offer training in Python, data science and technical
                  computing.
                </p>
                <p>
                  Our team has extensive experience teaching these topics and
                  offers a number of different courses that can be delivered
                  remotely and tailored to your specific needs.
                </p>
              </FaqAnswer>
            </Faq>

            <p className="mt-5 text-center">
              For any other enquiries you can contact us at{' '}
              <a
                href="mailto:hello@filament.so"
                target="_blank"
                rel="noreferrer"
              >
                hello@filament.so
              </a>
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="text-center mt-4">
            <SignupForm btnId="btnFaq" btnColor="pink" />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Home;
