import React from 'react';
import './IconCollection.scss';
import { Row, Col } from 'react-bootstrap';

import oxford from '../../images/logos/oxford_uni.svg';
import boeing from '../../images/logos/boeing.svg';
import ff from '../../images/logos/founders_factory.svg';
import Icon from './Icon';

const IconCollection = () => {
  return (
    <>
      <Row>
        <Col>
          <h4 className="text-center">Used by data scientists from</h4>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 4, offset: 4 }} className="text-center icon-wrapper">
          <Icon src={oxford} alt="" width={100} />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={6} className="text-center icon-wrapper">
          <Icon src={ff} alt="" width={120} />
        </Col>
        <Col xs={6} className="text-center icon-wrapper">
          <Icon src={boeing} alt="" width={170} />
        </Col>
      </Row>
    </>
  );
};

export default IconCollection;
