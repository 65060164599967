import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './Feature.scss';

import feature01 from '../../images/features/feature-01.gif';
import feature02 from '../../images/features/feature-02.gif';
import feature03 from '../../images/features/feature-03.gif';
import feature04 from '../../images/features/feature-04.gif';
const features = {
  feature01: feature01,
  feature02: feature02,
  feature03: feature03,
  feature04: feature04,
};

const newImage = (src) => {
  return <img src={src} alt={`Animated gif of application feature`} />;
};

const Feature = (props) => {
  const screenShotSide = props.screenShotSide === 'left' ? 'first' : 'last';
  const featureImg =
    props.screenshot && features.hasOwnProperty(props.screenshot)
      ? features[props.screenshot]
      : null;
  let imageElem = '';
  if (featureImg) {
    imageElem = newImage(featureImg);
  }
  return (
    <Row>
      <Col
        xs={12}
        md={{ span: 6, order: screenShotSide }}
        className="feature pt-5 pb-5"
      >
        <div className={`feature--mask  ${props.backdrop} ${props.rotate}`}>
          &nbsp;
        </div>
        <div className="feature__content">{imageElem}</div>
      </Col>
      <Col xs={12} md={6} className="feature__copy">
        {props.children}
      </Col>
    </Row>
  );
};

export default Feature;
