import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SignupForm from '../SignupForm/SignupForm';
import './Hero.scss';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Hero = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          file(relativePath: { eq: "notebook.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={(data) => (
        <Container>
          <Row>
            <Col
              xs={{ span: 12, offset: 0 }}
              lg={{ span: 6, offset: 0 }}
              className="column-hero-text"
            >
              <h2 className="h1">
                The notebook
                <br />
                for teams
              </h2>
              <h1 className="h2">
                Filament is a data science notebook that helps teams explore,
                visualise, and extract insights from data.
              </h1>
              <SignupForm btnId={'btnHero'} btnColor={'pink'} />
            </Col>
            <Col lg={6} xs={12} className="hero__screenshot mt-5 mt-lg-0">
              <Img
                title="Desktop Screenshot"
                alt="Screenshot of app"
                fluid={data.file.childImageSharp.fluid}
                width={800}
              />
            </Col>
          </Row>
        </Container>
      )}
    />
  );
};

export default Hero;
