import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './Faq.scss';
import Dot from '../Dot/Dot';
const Faq = (props) => {
  const offset = props.align === 'left' ? 0 : 5;
  const align = props.align === 'left' ? 'left' : 'right';
  return (
    <Row>
      <Col
        xs={12}
        md={{ span: 7, offset: offset }}
        className={`mt-4 mt-md-2 faq text-${align}`}
      >
        <Dot color={props.dotColor} align={align} />
        {props.children}
      </Col>
    </Row>
  );
};

export default Faq;
