import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import IconCollection from '../IconCollection/IconCollection';
import './Banners.scss';

const TopBanner = () => {
  return (
    <Container fluid className="top-banner blue">
      <Container>
        <Row>
          <Col md={12} lg={6} className="top-banner__text">
            <h2 className="mb-3">Everyone on the same page</h2>
            <p>
              Real-time collaboration on data, code, and context with the whole
              team.
            </p>
            <p>
              Filament requires no setup, can run on any device, and has an
              intuitive design that makes it easy for teams to work with data.
            </p>
          </Col>
          <Col md={12} lg={6} className="top-banner__icons mt-5 mt-lg-0">
            <IconCollection />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default TopBanner;
