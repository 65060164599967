import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Banners.scss';
import filamentIcon from '../../images/icons/filament-icon.svg';
import textLogo from '../../images/filament-text-logo.svg';
import SignupForm from '../SignupForm/SignupForm.js';

const BottomBanner = () => {
  return (
    <Container fluid className="bottom-banner purple">
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={12} className="text-center">
            <img src={filamentIcon} alt="Filament Icon" id="icon" />
            <br />
            <img src={textLogo} alt="Filament Text Logo" id="logo" />
            <h4>The data science notebook for teams</h4>
            <div id="faqNav"></div>
            <SignupForm btnId="btnBottomBanner" btnColor="inverse" />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default BottomBanner;
