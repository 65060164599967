import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Dot from '../Dot/Dot';
import './DashedBox.scss';

const DashedBox = (props) => {
  const topSide =
    (props.vBorder === 'right' && props.hBorder === 'top') ||
    (props.vBorder === 'left' && props.hBorder === 'bottom')
      ? 'left'
      : 'right';
  const bottomSide = topSide === 'left' ? 'right' : 'left';
  return (
    <Container className={props.class} id={!!props.id ? props.id : ''}>
      <Row>
        <Col
          xs={{ span: 6, offset: 3 }}
          md={{ span: props.span, offset: props.offset }}
          className={`dashedBox__col ${props.vBorder} ${props.hBorder}`}
        >
          <Dot
            position="top"
            side={topSide}
            color={props.topDot}
            dashed={true}
          />
          <Dot
            position="bottom"
            side={bottomSide}
            color={props.bottomDot}
            dashed={true}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default DashedBox;
