import React from 'react';

const Icon = (params) => {
  return (
    <div className="icon-wrapper__icon">
      <img
        src={params.src}
        alt={params.alt}
        style={{ width: params.width, maxWidth: '100%', height: 'auto' }}
      />
    </div>
  );
};

export default Icon;
